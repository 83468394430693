import { ComponentType, lazy, Suspense, useCallback, useEffect, useMemo, useState } from "react";
import IntlMessages from "./i18n/intl-messages";
import Root from "./routes";
import Theme from "./theme";

// MSAL imports
import BlockedDevices from "@/components/blocked-devcies";
import { loginRequest } from "@/config/msalConfig";
import { classesTenantBox, Icon, LogoIla26, TenantBox, TenantsBox } from "@/styles/style";
import logo from "@assets/images/ilan26Complete.svg";
import logoWhite from "@assets/images/ilan26White.svg";
import { InteractionType, IPublicClientApplication } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, MsalProvider, useMsal } from "@azure/msal-react";
import ErrorHandlerBox from "@components/error-handler-box";

import useCurrentUserHook from "@components/user-connection-modal/useCurrentUserHook.ts";
import { faArrowRightToBracket } from "@fortawesome/pro-light-svg-icons";
import {
  Box,
  Button,
  CircularProgress,
  SelectChangeEvent,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useLDClient, withLDProvider } from "launchdarkly-react-client-sdk";
import { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./App.css";
import { AbilityContext } from "./casl";
import useAppAbility from "./casl/useAppAbility.ts";
import useCheckDevice from "./hooks/useCheckDevice.ts";
import { RootState } from "./store/index.ts";
import { profileApi } from "./store/profile/endpoints/index.ts";
import { TenantMembership, TenantPlanType } from "./store/tenants/endpoints/tenants.ts";
import { selectTenant, shouldSelectTenantAction } from "./store/tenants/tenantsSlice.ts";
import LanguageCacheProvider from "./components/lang/LanguageCacheProvider.tsx";
import messages from "./components/user-connection-modal/messages.ts";
import { useIntl } from "react-intl";
import useDirection from "./hooks/useDirection.ts";

const UserConnectionModal = lazy(() => import("@components/user-connection-modal"));

type AppProps = {
  instance: IPublicClientApplication;
};

const AppLoadingComponent = () => {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      flex={1}
      justifyContent="center"
      alignItems="center"
      height="100vh"
      flexDirection="column"
      gap={2}
    >
      <CircularProgress color="primary" size={40} />
      <LogoIla26 src={theme.palette.mode === "dark" ? logoWhite : logo} style={{ height: 30 }} />
    </Box>
  );
};

const RootWrapper = () => {
  /**
   * useMsal is hook that returns the PublicClientApplication instance,
   * an array of all accounts currently signed in and an inProgress value
   * that tells you what msal is currently doing. For more, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
   */
  const { instance } = useMsal();

  const { formatMessage: __ } = useIntl();
  const currentDirection = useDirection();

  const { shouldSelectTenant, tenantsList, tenantId } = useSelector(
    (state: RootState) => state.tenant
  );

  const dispatch = useDispatch();
  const { ability, isLoading, error: abilityError } = useAppAbility();
  const { openUserModal, isLoadingUser } = useCurrentUserHook();
  const [isFirstConnectionModalOpen, setIsFirstConnectionModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!isLoadingUser) {
      setIsFirstConnectionModalOpen(openUserModal);
    }
  }, [isLoadingUser, openUserModal]);

  const isCurrentUserOwner = useMemo(() => {
    // Check if the current user is an owner
    return !!tenantsList?.find(
      tenant => tenant.id === tenantId && tenant.membership === TenantMembership.OWNER
    );
  }, [tenantsList, tenantId]);

  const error = useMemo(() => {
    if (abilityError && "status" in abilityError && "data" in abilityError) {
      return {
        ...(abilityError.data as {
          messages: string[];
          source: string;
          exception: string;
          errorId: string;
          supportMessage: string;
          statusCode: number;
          errorCode?: string;
        }),
        status: abilityError.status,
      };
    }
  }, [abilityError]);

  const [selectedTenant, setSelectedTenant] = useState<string>("");

  const handleChange = useCallback((event: SelectChangeEvent) => {
    setSelectedTenant(event.target.value as string);
  }, []);

  const handleSubmit = useCallback(() => {
    const activeAccount = instance.getActiveAccount();
    if (selectedTenant && activeAccount) {
      dispatch(selectTenant({ tenantId: selectedTenant, userId: activeAccount.localAccountId }));
      dispatch(shouldSelectTenantAction({ shouldSelectTenant: false, tenantsList: tenantsList }));
      profileApi.endpoints.getCurrentProfile.initiate();
    }
  }, [selectedTenant, instance, dispatch]);

  const { isProhibited } = useCheckDevice();

  const ldClient = useLDClient();
  useEffect(() => {
    const currentTenant = tenantsList?.find(tenant => tenant.id === tenantId);

    tenantId &&
      tenantsList &&
      ldClient &&
      ldClient.identify({
        kind: "tenant",
        plan:
          currentTenant?.plan === TenantPlanType.BASIC
            ? "Basic"
            : currentTenant?.plan === TenantPlanType.BUSINESS
            ? "Business"
            : "Enterprise",
        key: tenantId,
        name: currentTenant?.name as string,
      });
  }, [tenantId, ldClient, tenantsList]);

  if (isProhibited) return <BlockedDevices />;

  if (error && !location.pathname.includes("logout-session")) {
    switch (error.errorCode) {
      case "tenant_not_found":
        return <ErrorHandlerBox errorType={"enterprise"} />;
      case "token_not_found":
        return <ErrorHandlerBox errorType={"enterprise"} />;
      case "already_member":
        return <ErrorHandlerBox errorType={"enterprise"} />;
      case "invitation_already_accepted":
        return <ErrorHandlerBox errorType={"invitation"} />;
      case "tenant_subscription_expired":
        return <ErrorHandlerBox errorType={"subscriptionExpired"} />;
    }
  }

  if (isLoading && !location.pathname.includes("logout-session")) {
    return <AppLoadingComponent />;
  }

  if (
    shouldSelectTenant &&
    tenantsList &&
    tenantsList.length > 1 &&
    !location.pathname.startsWith("/invitations") // alow user to accept invitation before selecting tenant (uses startsWith to distinct from other /invitations routes e.g. network-ila26/my-network/invitations)
  ) {
    const theme = useTheme();
    return (
      <Stack justifyContent="center" alignItems="center" height="100vh" px="30%" spacing={2}>
        <LogoIla26 src={theme.palette.mode === "dark" ? logoWhite : logo} />
        <Typography>{__(messages.selectCompanyToContinue)}</Typography>
        <TenantsBox>
          {tenantsList
            .filter(tenant => tenant.plan !== TenantPlanType.BASIC)
            .map(item => (
              <TenantBox
                className={selectedTenant === item.id ? classesTenantBox.selected : ""}
                key={item.id}
                onClick={() =>
                  handleChange({
                    target: { value: item.id },
                  } as React.ChangeEvent<HTMLInputElement>)
                }
              >
                <Icon
                  icon={faArrowRightToBracket}
                  style={{
                    rotate: currentDirection === "rtl" ? "180deg" : "0deg",
                  }}
                />
                {item.name}
              </TenantBox>
            ))}
          <Box sx={{ textAlign: "right" }}>
            <Button variant={"contained"} onClick={handleSubmit} disabled={selectedTenant === ""}>
              {__(messages.continue)}
            </Button>
          </Box>
        </TenantsBox>
      </Stack>
    );
  }

  if (openUserModal && isCurrentUserOwner && !location.pathname.includes("logout-session")) {
    // Flag for operation that need to be done upon first sign in
    localStorage.setItem("first-connection", "true");

    return (
      <Suspense fallback={<AppLoadingComponent />}>
        <UserConnectionModal
          open={isFirstConnectionModalOpen}
          handleClose={() => setIsFirstConnectionModalOpen(false)}
        />
      </Suspense>
    );
  }

  return (
    <AbilityContext.Provider value={ability}>
      <Root />
    </AbilityContext.Provider>
  );
};

function App({ instance }: AppProps) {
  const authRequest = {
    ...loginRequest,
    forceRefresh: true,
    refreshTokenExpirationOffsetSeconds: 86400,
  };

  return (
    <MsalProvider instance={instance}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest}
        loadingComponent={AppLoadingComponent}
      >
        <IntlMessages>
          <LanguageCacheProvider>
            <Theme>
              <RootWrapper />
              <Toaster
                  position="top-center"
                  reverseOrder={false}
                  toastOptions={{
                    className: "react-hot-toast",
                  }}
                />
            </Theme>
          </LanguageCacheProvider>
        </IntlMessages>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
}

const AppWithLDProvider = withLDProvider({
  clientSideID: import.meta.env.VITE_APP_LAUNCHDARKLY_CLIENT_SIDE_ID,
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
})(App as ComponentType<object>);

export default AppWithLDProvider;
